@use 'mixins' as *;

.wrapper {
  padding-block: var(--spacing-medium) var(--spacing-large);

  @include breakpoint-min(small) {
    padding-block: var(--spacing-large) var(--spacing-xl);
  }
}

.title {
  margin-block-end: var(--spacing-regular);
}

.intro {
  max-width: 60ch;
}

.link {
  > * + * {
    margin-block-start: var(--spacing-xsmall);
  }
}

.grid {
  display: grid;
  margin-block-start: var(--spacing-medium);
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;

  @include breakpoint-min(small) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include breakpoint-min(medium) {
    grid-template-columns: repeat(6, 1fr);
  }
}
