.footer {
  --footer-bg-color: var(--color-black, #000000);
  --footer-font-family-heading: var(--font-family);
  --footer-font-family-regular: var(--font-family);
  --footer-font-weight-bold: var(--font-weight-h5, 500);
  --footer-font-weight-regular: var(--font-weight-p3, 300);
  --footer-heading-font-size: var(--font-size-h5, 1.25rem);
  --footer-heading-line-height: var(--line-height-h5, 28px);
  --footer-logo-color: var(--color-white, #fff);
  --footer-logo-size: 170px;
  --footer-spacing-l: var(--spacing-medium, 48px);
  --footer-spacing-small: var(--spacing-small, 16px);
  --footer-text-color-hover: var(--color-cta-blue, #5042f2);
  --footer-text-color: var(--color-white, #fff);
}
