.accordion-trigger {
  --accordion-indicator-rotation: 45deg;
  --accordion-indicator-size: 1.25rem;
  --accordion-trigger-padding: var(--spacing-small);
}

div.accordion-item {
  padding-inline: var(--spacing-small);
  padding-block: 0;
  border: none;
}

// TODO change to new link component once available
.item-link {
  padding-block: var(--spacing-small);
  padding-inline: var(--spacing-small);

  &--sub-item {
    display: block;
  }
}
