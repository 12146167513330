@use 'mixins' as *;

.root {
  --swiper-pagination-color: var(--color-white);
  --swiper-pagination-bullet-inactive-color: var(--color-white);
  --swiper-pagination-bottom: 40px;
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bullet-inactive-opacity: 0.4;
  --swiper-navigation-size: 38px;
  --swiper-navigation-color: var(--color-white);
  --swiper-navigation-sides-offset: 20px;
}
