.wrapper {
  margin-block: var(--spacing-medium);
}

.title {
  & + * {
    margin-block-start: var(--spacing-small);
  }
}

.intro {
  & + * {
    margin-block-start: var(--spacing-small);
  }
}

.text {
  white-space: pre-wrap;

  * + p {
    margin-block-start: var(--spacing-regular);
  }

  a {
    text-decoration: underline;
    color: var(--colors-hg-black);
    &[href^='tel'] {
      text-decoration: none;
    }
  }
}
