@use 'mixins' as *;

.video-block {
  background-color: var(--color-gray4);
  position: relative;
  inline-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-block: var(--spacing-medium);

  @include breakpoint-min(small) {
    margin-block: var(--spacing-large);
  }
}

.video-player {
  --media-object-fit: cover;
  --media-object-position: center;
  --dialog: none; /* Hide the error dialog */
  // --play-button: none;
  // --controls: none;

  position: relative;
}

.content-container {
  position: absolute;
  gap: 32px;
  display: flex;
  flex-direction: column;
  max-inline-size: calc(90vw);
  z-index: 2;

  @include breakpoint-min(small) {
    max-inline-size: calc(75vw);
  }
}

.controls {
  button {
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    transform: translate(-50%, -50%);
    inline-size: 56px;
    block-size: 56px;
    padding: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    cursor: pointer;
    background: rgb(33 33 38 / 60%);
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid var(--color-gray1);

    &:hover {
      background-color: var(--color-gray3);
    }

    &.paused {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease;
    }

    &.visible {
      opacity: 1;
      pointer-events: initial;
      transition: opacity 0.3s ease;
    }

    svg path {
      opacity: 1;
      fill: var(--color-gray1);
    }

    &::before {
      @include breakpoint-min(small) {
        content: '';
        position: absolute;
        block-size: 100%;
        inline-size: 100%;
        inset-block-start: 0;
        inset-inline-start: 0;
        background: rgb(33 33 38 / 20%);
        transform: translateX(-100%);
        pointer-events: none;
        z-index: -1;
      }
    }

    &:hover::before {
      transform: translateX(0);
    }

    @include breakpoint-min(small) {
      inline-size: 56px;
      block-size: 56px;
    }
  }
}
