@use 'mixins' as *;

.wrapper {
  display: flex;
  align-items: flex-start;
  padding: 0 var(--spacing-regular);
  margin-block-start: var(--spacing-medium);
  flex-direction: column;
  gap: var(--spacing-xsmall);
  @include breakpoint-min(small) {
    padding: 0;
  }
}

.label {
  &:empty {
    display: none;
  }
}
