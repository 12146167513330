@use 'mixins' as *;

.wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--spacing-menu-height);
  text-align: center;
  z-index: 10;
  background-color: var(--color-black);
  padding: 0 var(--spacing-regular);

  @include breakpoint-min(small) {
    padding-inline: var(--spacing-medium);
  }
}
