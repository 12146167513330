@use 'mixins' as *;

.wrapper {
  padding: 0 var(--spacing-regular);
  margin-top: var(--spacing-large);
  margin-bottom: var(--spacing-xl);

  @include breakpoint-min(small) {
    padding: 0;
  }
}

.title {
  padding-bottom: var(--spacing-xsmall);
}

.button {
  margin-inline: auto;
  margin-block-start: var(--spacing-xsmall);
}
