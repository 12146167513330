@use 'mixins' as *;

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  inline-size: fit-content;
  block-size: var(--spacing-medium);
  min-inline-size: 147px;
  padding: var(--spacing-xsmall) var(--spacing-xregular);
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
  font-size: var(--font-size-subheading);
  line-height: var(--line-height-subheading);
  font-weight: var(--font-weight-sub-heading);
  letter-spacing: 0.2em;
  text-transform: uppercase;
  border-radius: 50px;
  background-color: transparent;
  box-sizing: border-box;
  position: relative;
  background-color: var(--color-cta-blue);
  color: var(--color-white);

  &:disabled {
    cursor: not-allowed;
  }

  @include hover {
    background-color: transparent;
    color: var(--hover-text-color, #fff);

    &::after {
      content: '';
      position: absolute;
      inline-size: 100%;
      block-size: 100%;
      inset: 0;
      border: 2px solid var(--color-cta-blue);
      border-radius: 50px;
      -webkit-mask-image: linear-gradient(
        0deg,
        white 16px,
        transparent 16px,
        transparent 32px,
        white 16px
      );
    }
  }
}
